import SlateEditor from "components/UI/SlateEditor";
import dayjs from "dayjs";
import { Descendant } from "types/CustomTypes";
import { ReportContents } from "types/Report";
import { ReportState } from "types/ReportInfo";

type IReportResponse = {
  reportContents: ReportContents;
  reportState: ReportState;
};

const Report = ({ reportContents, reportState }: IReportResponse) => {
  const { title, sections } = reportContents;
  const { patientInfo, reportDates, doctorInfo } = reportState;
  const manipulatedSections = [...sections];

  const values: Descendant[] = [
    {
      type: "table",
      children: [
        {
          type: "table-row",
          children: [
            {
              type: "table-cell",
              children: [{ text: `Nom du patient: ${patientInfo.patientName}` }],
            },
            {
              type: "table-cell",
              children: [
                { text: `Nom du médecin demandeur: ${doctorInfo.requestingDoctor ? doctorInfo.requestingDoctor : ""}` },
              ],
            },
          ],
        },
        {
          type: "table-row",
          children: [
            {
              type: "table-cell",
              children: [{ text: `Sexe du patient: ${patientInfo.patientSex}` }],
            },
            {
              type: "table-cell",
              children: [{ text: `Nom du radiologue: ${doctorInfo?.radiologistDoctor}` }],
            },
          ],
        },
        {
          type: "table-row",
          children: [
            {
              type: "table-cell",
              children: [{ text: `Âge du patient: ${patientInfo.patientAge}` }],
            },
            {
              type: "table-cell",
              children: [{ text: `Date de l'examen: ${reportDates.dateOfExam.format("DD/MM/YYYY")}` }],
            },
          ],
        },
        {
          type: "table-row",
          children: [
            {
              type: "table-cell",
              children: [{ text: `INS: ${patientInfo.ins}` }],
            },
            {
              type: "table-cell",
              children: [
                {
                  text: `Date de saisie du CRR: ${
                    reportDates.dateOfEntryOfCCR?.format("DD/MM/YYYY") || dayjs().format("DD/MM/YYYY")
                  }`,
                },
              ],
            },
          ],
        },
      ],
    },
    { type: "heading1", children: [{ text: title, bold: true }], align: "center" },
    ...manipulatedSections
      .map(section => [
        { type: "heading2", children: [{ text: section.heading, bold: true }] },
        { type: "paragraph", children: [{ text: section.text }] },
      ])
      .reduce((v, c) => [...v, ...c], []),
  ] as Descendant[];

  return (
    <SlateEditor
      key={
        patientInfo.ins +
        patientInfo.patientName +
        patientInfo.patientAge +
        patientInfo.patientSex +
        reportDates.dateOfExam +
        reportDates.dateOfEntryOfCCR +
        doctorInfo.requestingDoctor +
        doctorInfo.radiologistDoctor
      }
      reportContents={values}
    />
  );
};

export default Report;
